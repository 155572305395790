import type { Router, LocationQueryRaw } from 'vue-router';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css';

import { useUserStore } from '@/store';
import { isLogin } from '@/utils/auth';
import { Message } from '@arco-design/web-vue';

export default function setupUserLoginInfoGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    NProgress.start();

    // if (from.path.includes('/app/approval')) {
    //   history.replaceState({ back: from.path }, to.name, '');
    // }

    const userStore = useUserStore();
    // next();
    if (isLogin()) {
      if (userStore.role) {
        next();
      } else {
        try {
          await userStore.info();
          next();
        } catch (error) {
          await userStore.logout();
          next({
            name: 'login',
            query: {
              redirect: to.name,
              ...to.query
            } as LocationQueryRaw
          });
        }
      }
    } else {
      // 不需要token就能访问的页面
      if (to.name === 'login' || to.name === 'EntryApplicationInviteMobile') {
        next();
        return;
      }
      router.push({
        name: 'login'
      });
      Message.error('用户信息过期，请重新登录！');
      // next({
      //   name: 'login',
      //   query: {
      //     redirect: to.name,
      //     ...to.query
      //   } as LocationQueryRaw
      // });
    }
  });
}
