import {
  createRouter,
  createWebHistory,
  createWebHashHistory
} from 'vue-router';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css';
import { DEFAULT_LAYOUT } from './routes/base';

import { appClientRoutes, appManagementRoutes } from './routes';
import { REDIRECT_MAIN, NOT_FOUND_ROUTE } from './routes/base';
import createRouteGuard from './guard';

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: 'login'
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Management/Login/index.vue'),
      meta: {
        requiresAuth: false,
        locale: '登录'
      }
    },
    {
      path: '/entryApplicationInviteMobile',
      name: 'EntryApplicationInviteMobile',
      component: () =>
        import(
          '@/views/Management/Human/ChangeManagement/InductionManagement/EntryApplicationSetting/InviteMobile/index.vue'
        ),
      meta: {
        locale: '入职申请',
        requiresAuth: false,
        roles: ['*']
        // hideInMenu: true
      }
    },
    {
      path: '/myHomePage',
      name: 'MyHomePage',
      component: () =>
        import('@/views/Management/Home/WorkPlace/MyHomePage/index.vue'),
      meta: {
        locale: '我的主页',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true
      }
    },
    {
      path: '/myVideo',
      name: 'MyVideo',
      component: () =>
        import(
          '@/views/Management/Home/WorkPlace/MyHomePage/SendVideo/index.vue'
        ),
      meta: {
        locale: '发布视频',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true
      }
    },
    {
      path: '/myArticle',
      name: 'MyArticle',
      component: () =>
        import(
          '@/views/Management/Home/WorkPlace/MyHomePage/PostArticle/index.vue'
        ),
      meta: {
        locale: '写文章',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true
      }
    },
    // {
    //   path: '/form',
    //   name: 'form',
    //   component: () => import('@/views/Management/test.vue'),
    //   meta: {
    //     requiresAuth: false
    //   }
    // },
    {
      path: '/pic',
      name: 'pic',
      component: () => import('@/views/Management/pic.vue'),
      meta: {
        requiresAuth: false
      }
    },

    {
      path: '/testhu',
      name: 'testhu',
      component: () => import('@/views/text/index.vue'),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/app/app-vue3',
      name: 'app-vue3',
      component: () => import('@/views/text/index.vue'),
      meta: {
        title: 'Test',
        isMenu: true,
        requiresAuth: false
      }
    },
    REDIRECT_MAIN,
    NOT_FOUND_ROUTE,
    ...appManagementRoutes,
    {
      path: '/client',
      name: 'Client',
      component: DEFAULT_LAYOUT,
      redirect: {
        name: 'C_ClientWorkPlace'
      },
      // meta: {
      //   requiresAuth: true,
      //   roles: ['user']
      // },
      children: [...appClientRoutes]
    }
    // {
    //   path: '/:catchAll(.*)',
    //   name: 'NotFound',
    //   redirect: '/app/approval' // 重定向到首页
    //   // 或者你可以指定一个特定的组件作为“NotFound”页面
    //   // component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue')
    // }
    // ...appSupplierRoutes,
  ],
  // scrollBehavior() {
  //   return { top: 0 };
  // }
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

createRouteGuard(router);

export default router;
