import type { Router } from 'vue-router';
import { setRouteEmitter } from '@/utils/route-listener';
import setupUserLoginInfoGuard from './userLoginInfo';
import setupPermissionGuard from './permission';
import NProgress from 'nprogress'; // progress bar

// 全局路由守卫
function setupPageGuard(router: Router) {
  router.beforeEach(async (to, from) => {
    // console.log('from.path', from.path);

    // if (from.path.includes('/app/approval')) {
    //   history.replaceState({ back: from.path }, to.name, '');
    // }
    // emit route change
    // 手机端扫码外部入职
    if(to.name === 'EntryApplicationInviteMobile') {
      
    } else {
      // 获取内部token
      let orgToken = localStorage.getItem('oriToken') || localStorage.getItem('token') || '';
      localStorage.setItem('token',orgToken)
      // // token设置为内部token
      // localStorage.setItem(TOKEN_KEY, token);
      // console.log('其他页面');
      
    }
    setRouteEmitter(to);
  });
}

export default function createRouteGuard(router: Router) {
  // 按顺序执行
  setupPageGuard(router);
  setupUserLoginInfoGuard(router);
  setupPermissionGuard(router);
}
