import type { Router, RouteRecordNormalized } from 'vue-router';
import router from '@/router';
import NProgress from 'nprogress'; // progress bar

import 'nprogress/nprogress.css';
import usePermission from '@/hooks/permission';
import { useUserStore, useAppStore } from '@/store';
import { appClientRoutes } from '../routes';
import { WHITE_LIST, NOT_FOUND } from '../constants';
// const router = Router();
export default function setupPermissionGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    // console.log(2, to, from, router);

    const appStore = useAppStore();
    // console.log('from.path', from.path);
    // if (from.path.includes('/app/approval')) {
    //   history.replaceState({ back: from.path }, to.name, '');
    // }
    const userStore = useUserStore();
    const Permission = usePermission();
    const permissionsAllow = Permission.accessRouter(to);
    if (appStore.menuFromServer) {
      // 针对来自服务端的菜单配置进行处理
      // Handle routing configuration from the server

      // 根据需要自行完善来源于服务端的菜单配置的permission逻辑
      // Refine the permission logic from the server's menu configuration as needed
      if (
        !appStore.appAsyncMenus.length &&
        !WHITE_LIST.find((el) => el.name === to.name)
      ) {
        await appStore.fetchServerMenuConfig();
      }
      const serverMenuConfig = [...appStore.appAsyncMenus, ...WHITE_LIST];

      let exist = false;
      while (serverMenuConfig.length && !exist) {
        const element = serverMenuConfig.shift();
        if (element?.name === to.name) exist = true;

        if (element?.children) {
          serverMenuConfig.push(
            ...(element.children as unknown as RouteRecordNormalized[])
          );
        }
      }
      if (exist && permissionsAllow) {
        console.log(78);

        next();
      } else next(NOT_FOUND);
    } else {
      // eslint-disable-next-line no-lonely-if

      // 如果该路由允许通过了
      if (permissionsAllow) {
        // 网页标题，随着每个路由的locale名称变化而变化
        document.title = to.meta.locale as string;
        next();
      } else {
        const destination =
          Permission.findFirstPermissionRoute(
            appClientRoutes,
            // userStore.role
            localStorage.getItem('userRole') as any
          ) || NOT_FOUND;
        next(destination);
      }
    }
    NProgress.done();
  });
}
